<script>
export default {
    name: 'redirect',
    beforeCreate() {
        const { params, query } = this.$route;
        const { path } = params;
        this.$router.replace({ path: '/' + path, query });
    },
    mounted() {
        this.removeKeepAlivePage('redirect');
    },
    destroyed() {
        this.insertKeepAlivePage(this.$route.query.routeName);
    },
    render: function(h) {
        return h();
    }
};
</script>
